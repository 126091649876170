import React, { useState, useEffect } from 'react';
import { getDevices, getReport } from '../processing/getData'; 
import moment from 'moment-timezone';
import banner from '../icons/reportes.png';
import './Reports.css';

const Reports = () => {
  const [vehicles, setVehicles] = useState([]);
  const [type, setType] = useState('');
  const [overspeed, setOverspeed] = useState(false);
  const [speed, setSpeed] = useState('');
  const [items, setItems] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [vehicleFilter, setVehicleFilter] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [availableItems, setAvailableItems] = useState([]);

  useEffect(() => {
    const fetchVehicles = async () => {
      const userId = localStorage.getItem('user_id');
      if (!userId) {
        alert('No hay user_id almacenado en localStorage');
        return;
      }

      try {
        const devicesData = await getDevices(userId);
        if (devicesData && devicesData.devices) {
          setVehicles(devicesData.devices);
          setFilteredVehicles(devicesData.devices); // Inicializa los vehículos filtrados
        }
      } catch (error) {
        console.error('Error al obtener los dispositivos:', error);
      }
    };

    fetchVehicles();
  }, []);

  useEffect(() => {
    // Actualiza availableItems dependiendo del valor de 'type'
    switch (type) {
      case 'completeReport':
        setAvailableItems(['Evento', 'Distancia km','Velocidad km/h','Dirección','Lugar más cercano','Latitud','Longitud','Orientación','Ver mapa']);
        setItems(['Evento', 'Distancia km','Velocidad km/h','Dirección','Lugar más cercano','Latitud','Longitud','Orientación','Ver mapa']);
        break;
      case 'activityReport':
        setAvailableItems(['Inicio de labores', 'Fin de labores','Horas de motor','Horas de conducción','Ubicación inicial','Ubicación final','Velocidad media km/h','Velocidad máxima km/h']);
        setItems(['Inicio de labores', 'Fin de labores','Horas de motor','Horas de conducción','Ubicación inicial','Ubicación final','Velocidad media km/h','Velocidad máxima km/h']);
        break;
      case 'alertResume':
        setAvailableItems(['Cantidad de aceleraciones bruscas','Cantidad de frenadas bruscas','Cantidad de excesos de velocidad','Excesos de velocidad en geocercas','Cantidad de giros bruscos', 'Distancia recorrida','Ubicación inicial','Ubicación final','Velocidad media km/h','Velocidad máxima km/h','Separar por placa']);
        setItems(['Cantidad de aceleraciones bruscas','Cantidad de frenadas bruscas','Cantidad de excesos de velocidad','Excesos de velocidad en geocercas','Cantidad de giros bruscos', 'Distancia recorrida','Ubicación inicial','Ubicación final','Velocidad media km/h','Velocidad máxima km/h']);
        break;
      case 'alertTracking':
        setAvailableItems(['Evento', 'Distancia km','Velocidad km/h','Dirección','Lugar más cercano','Latitud','Longitud','Orientación','Ver mapa','Separar por placa']);
        setItems(['Evento', 'Distancia km','Velocidad km/h','Dirección','Lugar más cercano','Latitud','Longitud','Orientación','Ver mapa']);
        break;
      case 'overspeeds':
        setAvailableItems(['Evento','Velocidad km/h','Dirección','Lugar más cercano','Latitud','Longitud','Orientación','Ver mapa','Separar por placa']);
        setItems(['Evento','Velocidad km/h','Dirección','Lugar más cercano','Latitud','Longitud','Orientación','Ver mapa']);
        break;
      default:
        setAvailableItems([]);
    }
  }, [type]);

  const handleVehicleChange = (e) => {
    setSelectedVehicle(e.target.value);
  };

  const handleSpeedChange = (e) => {
    setSpeed(e.target.value);
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
    if(e.target.value=='overspeeds')
    {
      setOverspeed(true);
    }else{
      setOverspeed(false);
    }
    setAvailableItems([]);
    setItems([]);
  };

  const handleItemsChange = (e) => {
    const { value, checked } = e.target;
    setItems(prevItems =>
      checked ? [...prevItems, value] : prevItems.filter(item => item !== value)
    );
  };

  const handleVehicleFilterChange = (e) => {
    const filter = e.target.value;
    setVehicleFilter(filter);
    const filtered = vehicles.filter(vehicle => 
      vehicle.plate.toLowerCase().includes(filter.toLowerCase()) || 
      vehicle.name.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredVehicles(filtered);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSubmit = async () => {
    setError(null);
  
    if (!selectedVehicle || !startDate || !endDate) {
      alert('Por favor, selecciona un vehículo y un rango de fechas.');
      return;
    }
  
    const start = new Date(startDate);
    const end = new Date(endDate);
  
    if (start >= end) {
      alert('La fecha de inicio debe ser menor que la fecha de fin.');
      return;
    }
  
    const diffHours = (end - start) / (1000 * 60 * 60);
    if (diffHours > 168) {
      alert('El rango de fechas no puede ser mayor a 7 días.');
      return;
    }
  
    setLoading(true);
  
    try {
      const formattedStart = start.toISOString().slice(0, 19).replace('T', ' ');
      const formattedEnd = end.toISOString().slice(0, 19).replace('T', ' ');
  
      const requestData = {
        vehicleId: selectedVehicle,
        startDate: formattedStart,
        endDate: formattedEnd,
        reportType: type,
        items:items,
        speed: overspeed ? speed : undefined,
      };
      const fileName=await getReport(requestData);
  
      // Descargar el archivo después de la generación del reporte
      
      const fileUrl = `http://164.68.113.217:3821/${fileName.name}`;
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      
      
    } catch (error) {
      setError('Error al generar el reporte.');
      console.error('Error al generar el reporte:', error);
    } finally {
      setLoading(false);
    }
  }  

  return (
    <div className="reports-container">
      <img src={banner} alt="Banner" className="banner-report" />
      <div className="history-content">
        <div className="history-filters">
          <div className="type-select">
            <select value={type} onChange={handleTypeChange}>
              <option value="">Seleccionar</option>
              <option value="completeReport">Reporte Completo</option>
              <option value="activityReport">Reporte de Actividad</option>
              <option value="alertResume">Resumen de Alertas</option>
              <option value="alertTracking">Seguimiento de Alertas</option>
              <option value="overspeeds">Excesos de velocidad</option>
            </select>
          </div>

          <div className="items-select">
            <div className="checkbox-group">
              {availableItems.map((item) => (
                <label key={item}>
                  <input
                    type="checkbox"
                    value={item}
                    onChange={handleItemsChange}
                    checked={items.includes(item)}
                  />
                  {item}
                </label>
              ))}
            </div>
          </div>
          {overspeed?(
            <div className="speed-limit">
              <label htmlFor="limit-label">Límite de velocidad km/h:</label>
              <input
                type="text"
                id="limit-value"
                value={speed}
                onChange={handleSpeedChange}
              />
            </div>
          ):null
          }
          <div className="date-inputs">
            <div className="date-input">
              <label htmlFor="start-date">Desde:</label>
              <input
                type="datetime-local"
                id="start-date"
                value={startDate}
                onChange={handleStartDateChange}
              />
            </div>
            <div className="date-input">
              <label htmlFor="end-date">Hasta:</label>
              <input
                type="datetime-local"
                id="end-date"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </div>
          </div>

          <div className="vehicle-select">
            <input
              type="text"
              placeholder="Filtrar..."
              value={vehicleFilter}
              onChange={handleVehicleFilterChange}
              className="vehicle-filter"
            />
            <select value={selectedVehicle} onChange={handleVehicleChange}>
              <option value="">Seleccionar</option>
              {filteredVehicles.map((vehicle) => (
                <option key={vehicle.id} value={vehicle.traccar_id}>
                  {vehicle.plate}
                </option>
              ))}
            </select>
            <button onClick={handleSubmit}>Guardar</button>
          </div>
        </div>

        {loading ? (
          <div className="loading-spinner">Cargando...</div>
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : null}
      </div>
    </div>
  );
};

export default Reports;
