import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { getPositionDevices } from '../processing/getData';
import carIcon from '../icons/car.png';
import banner from '../icons/ubicacion.png';
import vehiclesIcon from '../icons/cars.png';
import './Maps.css';

function formatDate(dateString) {
  const date = new Date(dateString);
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${hours}:${minutes} ${day}-${month}-${year}`;
}

const getAddrees = async (latitude, longitude) => {
  try {
    const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
    if (!response.ok) throw new Error('Error al obtener la dirección');
    const data = await response.json();
    return data.display_name;
  } catch (error) {
    console.error(error);
    return 'Dirección no disponible';
  }
};

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const getColorForSpeed = (state, time) => {
  if (state === 'Sin actividad') return '#393939';
  if (state === 'Apagado') return 'red';
  if (state === 'Encendido') return 'yellow';
  if (state === 'Movimiento') return 'green';
  return 'red';
};

const CenterMapOnVehicleClick = ({ selectedVehicle, shouldFocusOnVehicle }) => {
  const map = useMap();

  useEffect(() => {
    if (shouldFocusOnVehicle && selectedVehicle && selectedVehicle.position) {
      map.flyTo(selectedVehicle.position, 17, { duration: 0.5 })
    }
  }, [selectedVehicle, shouldFocusOnVehicle, map]);

  return null;
};

const VehicleMenu = ({ vehicles, onSelectVehicle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };

  const filteredVehicles = vehicles.filter((vehicle) =>
    vehicle.plate.toLowerCase().includes(searchText) || vehicle.name.toLowerCase().includes(searchText)
  );

  return (
    <>
      <div className="vehicle-icon-container" onClick={handleMenuClick}>
        <img src={vehiclesIcon} alt="Vehicles" className="vehicles-icon" />
      </div>
      {isOpen && (
        <div className="vehicle-menu">
          <div className="search-container">
            <input
              type="text"
              placeholder="Buscar vehículo..."
              value={searchText}
              onChange={handleSearchChange}
              className="search-input"
            />
          </div>
          <div className="vehicle-list">
            {filteredVehicles.length > 0 ? (
              filteredVehicles.map((vehicle, index) => (
                <div
                  key={index}
                  className="vehicle-item"
                  onClick={() => onSelectVehicle(vehicle)}
                >
                  <div
                    className="vehicle-status"
                    style={{ backgroundColor: getColorForSpeed(vehicle.state, vehicle.time) }}
                  ></div>
                  <div className="vehicle-plate">{vehicle.plate}</div>
                  <div className="vehicle-time">{vehicle.time}</div>
                  <div className="vehicle-name">{vehicle.name}</div>
                  <div className="vehicle-message">{vehicle.message}</div>
                </div>
              ))
            ) : (
              <div className="no-results">No se encontraron vehículos</div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const Mapa = () => {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [addresses, setAddresses] = useState({});
  const [shouldFocusOnVehicle, setShouldFocusOnVehicle] = useState(false);

  const fetchAndSetVehicles = async () => {
    const userId = localStorage.getItem('user_id');
    if (!userId) {
      alert('No hay user_id almacenado en localStorage');
      return;
    }

    const devicesData = await getPositionDevices(userId);
    if (devicesData && devicesData.devices) {
      const updatedVehicles = devicesData.devices.map(device => ({
        position: [device.latitude, device.longitude],
        plate: device.plate,
        speed: device.speed,
        state: device.state,
        time: formatDate(device.time),
        message: device.message,
        traccar_id: device.traccar_id,
        name: device.name,
        longitude: device.longitude,
        latitude: device.latitude
      }));
      setVehicles(updatedVehicles);

      if (selectedVehicle) {
        const updatedVehicle = updatedVehicles.find(v => v.traccar_id === selectedVehicle.traccar_id);
        if (updatedVehicle && (
            updatedVehicle.position[0] !== selectedVehicle.position[0] ||
            updatedVehicle.position[1] !== selectedVehicle.position[1]
          )) {
          setSelectedVehicle(updatedVehicle);
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAndSetVehicles();
    const interval = setInterval(fetchAndSetVehicles, 15000);
    return () => clearInterval(interval);
  }, [selectedVehicle]);

  const handleVehicleSelection = (vehicle) => {
    setSelectedVehicle(vehicle);
    setShouldFocusOnVehicle(true);  // Solo enfoca el mapa al seleccionar un vehículo
  };

  useEffect(() => {
    const fetchAddresses = async () => {
      if (selectedVehicle) {
        const address = await getAddrees(selectedVehicle.latitude, selectedVehicle.longitude);
        setAddresses((prev) => ({
          ...prev,
          [selectedVehicle.traccar_id]: address,
        }));
      }
    };

    fetchAddresses();
  }, [selectedVehicle]);

  return (
    <div>
      {loading && (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      )}
      <img src={banner} alt="Banner" className="banner-maps" />
      <VehicleMenu vehicles={vehicles} onSelectVehicle={handleVehicleSelection} />
      <MapContainer
        id="map"
        center={[0.6703, -76.8731]}
        zoom={13}
        style={{ height: "calc(100vh - 70px)", width: "calc(100vw - 30px)", position: "fixed", left: "15px", top: "60px" }}
        attributionControl={false}
      >
        <TileLayer
          url="https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
          maxZoom={20}
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
        />
        <CenterMapOnVehicleClick selectedVehicle={selectedVehicle} shouldFocusOnVehicle={shouldFocusOnVehicle} />

        {selectedVehicle && selectedVehicle.position && (
          <Marker
            key={selectedVehicle.traccar_id}
            position={selectedVehicle.position}
            icon={L.divIcon({
              className: 'custom-icon',
              html: `<div class="icon-container">
                      <div class="circle" style="background-color: ${getColorForSpeed(selectedVehicle.state, selectedVehicle.time)}">
                        <img src="${carIcon}" alt="car" class="car-icon" />
                      </div>
                      <div class="plate-label"><strong>${selectedVehicle.plate}</strong></div>
                    </div>`,
              iconSize: [36, 36],
              iconAnchor: [18, 18]
            })}
            eventHandlers={{
              popupopen: () => setShouldFocusOnVehicle(true),  // Activa el enfoque al abrir el Popup
              popupclose: () => setShouldFocusOnVehicle(false) // Desactiva el enfoque al cerrar el Popup
            }}
          >
            <Popup maxWidth={250}>
              <div>
                <strong>Placa:</strong> {selectedVehicle.plate} <br />
                <strong>Nombre:</strong> {selectedVehicle.name} <br />
                <strong>Estado:</strong> {selectedVehicle.state} <br />
                <strong>Evento:</strong> {selectedVehicle.message} <br />
                <strong>Hora:</strong> {selectedVehicle.time} <br />
                <strong>Dirección:</strong> {addresses[selectedVehicle.traccar_id] || 'Cargando...'}
              </div>
            </Popup>
          </Marker>
        )}
      </MapContainer>
    </div>
  );
};

export default Mapa;
