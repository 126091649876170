import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { getHistory, getDevices } from '../processing/getData';
import moment from 'moment-timezone';
import L from 'leaflet';
import banner from '../icons/historial.png';
import './History.css';
import 'leaflet/dist/leaflet.css';

function formatDate(dateString) {
  const date = new Date(dateString);
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${day}-${month}-${year} ${hours}:${minutes}`;
}

// Configurar el ícono del marcador de Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const getAddrees = async (latitude, longitude) => {
  try {
    const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
    if (!response.ok) throw new Error('Error al obtener la dirección');
    const data = await response.json();
    return data.display_name;
  } catch (error) {
    console.error(error);
    return 'Dirección no disponible';
  }
};

const History = () => {
  const [vehicles, setVehicles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [vehicleFilter, setVehicleFilter] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [historyData, setHistoryData] = useState([]);
  const [messageFilter, setMessageFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [mapPosition, setMapPosition] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [popupData, setPopupData] = useState({});

  useEffect(() => {
    const fetchVehicles = async () => {
      const userId = localStorage.getItem('user_id');
      if (!userId) {
        alert('No hay user_id almacenado en localStorage');
        return;
      }

      try {
        const devicesData = await getDevices(userId);
        if (devicesData && devicesData.devices) {
          setVehicles(devicesData.devices);
          setFilteredVehicles(devicesData.devices);
        }
      } catch (error) {
        console.error('Error al obtener los dispositivos:', error);
      }
    };

    fetchVehicles();
  }, []);

  const handleVehicleChange = (e) => {
    setSelectedVehicle(e.target.value);
  };

  const handleVehicleFilterChange = (e) => {
    const filter = e.target.value;
    setVehicleFilter(filter);
    const filtered = vehicles.filter(vehicle =>
      vehicle.plate.toLowerCase().includes(filter.toLowerCase()) || vehicle.name.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredVehicles(filtered);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleMessageFilterChange = (e) => {
    setMessageFilter(e.target.value);
  };

  const handleSubmit = async () => {
    setError(null);
    if (!selectedVehicle || !startDate || !endDate) {
      alert('Por favor, selecciona un vehículo y un rango de fechas.');
      return;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start >= end) {
      alert('La fecha de inicio debe ser menor que la fecha de fin.');
      return;
    }

    const diffHours = (end - start) / (1000 * 60 * 60);
    if (diffHours > 48) {
      alert('El rango de fechas no puede ser mayor a 48 horas.');
      return;
    }

    setLoading(true);

    if (showMap) {
      setShowMap(false);
      setMapPosition(null);
    }

    try {
      const adjustedStart = new Date(start.getTime() - (start.getTimezoneOffset() * 60 * 1000));
      const adjustedEnd = new Date(end.getTime() - (end.getTimezoneOffset() * 60 * 1000));
      const formattedStart = adjustedStart.toISOString().slice(0, 19).replace('T', ' ');
      const formattedEnd = adjustedEnd.toISOString().slice(0, 19).replace('T', ' ');
      const history = await getHistory(selectedVehicle, formattedStart, formattedEnd);
      if (history && history.history) {
        setHistoryData(history.history);
      }
    } catch (error) {
      setError('Error al obtener el historial.');
      console.error('Error al obtener el historial:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = async (record) => {
    setMapPosition([record.latitude, record.longitude]);
    setShowMap(true);

    const device = vehicles.filter(vehicle =>
      vehicle.traccar_id == selectedVehicle
    );
    const fetchedAddress = await getAddrees(record.latitude, record.longitude);
    const data = {
      name: device[0].name,
      plate: device[0].plate,
      message: record.message,
      time: formatDate(record.time),
      speed: record.speed,
      state: record.state,
      address: fetchedAddress
    };
    setPopupData(data);
  };

  const handleCloseMap = () => {
    setShowMap(false);
    setMapPosition(null);
    setPopupData({});
  };

  const filteredHistoryData = historyData.filter(record =>
    record.message.toLowerCase().includes(messageFilter.toLowerCase())
  );

  return (
    <div className="history-container">
      <img src={banner} alt="Banner" className="banner-history" />
      <div className="history-content">
        <div className="history-filters">
          <div className="date-inputs">
            <div className="date-input">
              <label htmlFor="start-date">Desde:</label>
              <input
                type="datetime-local"
                id="start-date"
                value={startDate}
                onChange={handleStartDateChange}
              />
            </div>
            <div className="date-input">
              <label htmlFor="end-date">Hasta:</label>
              <input
                type="datetime-local"
                id="end-date"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </div>
          </div>
          <div className="vehicle-select">
            <input
              type="text"
              placeholder="Filtrar vehículos..."
              value={vehicleFilter}
              onChange={handleVehicleFilterChange}
              className="vehicle-filter"
            />
            <select value={selectedVehicle} onChange={handleVehicleChange}>
              <option value="">Seleccionar</option>
              {filteredVehicles.map((vehicle) => (
                <option key={vehicle.id} value={vehicle.traccar_id}>
                  {vehicle.plate}
                </option>
              ))}
            </select>
            <button onClick={handleSubmit}>Consultar</button>
          </div>
        </div>
        <div className="message-filter">
          <label htmlFor="message-filter">Filtrar:</label>
          <input
            type="text"
            id="message-filter"
            value={messageFilter}
            onChange={handleMessageFilterChange}
            placeholder="Mensaje..."
          />
        </div>
        {loading ? (
          <div className="loading-spinner">Cargando...</div>
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : (
          <div className="history-table-container">
            <table className="history-table">
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Mensaje</th>
                  <th>Velocidad</th>
                  <th>Distancia</th>
                  <th>Estado</th>
                  <th>Latitud</th>
                  <th>Longitud</th>
                </tr>
              </thead>
              <tbody>
                {filteredHistoryData.map((record, index) => (
                  <tr key={index} onClick={() => handleRowClick(record)}>
                    <td>{formatDate(record.time)}</td>
                    <td>{record.message}</td>
                    <td>{record.speed}</td>
                    <td>{record.distance.toFixed(2)}</td>
                    <td>{record.state}</td>
                    <td>{record.latitude}</td>
                    <td>{record.longitude}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {showMap && (
        <div className="map-overlay">
        <button className="closeButton" onClick={handleCloseMap}><strong>X</strong></button>
        <MapContainer center={mapPosition} zoom={13} style={{ height: '100%', width: '100%' }}>
          <TileLayer
            url="https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
            maxZoom={20}
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          />
          <Marker position={mapPosition}>
            <Popup maxWidth={250}>
              <strong>Vehículo:</strong> {popupData.name}<br />
              <strong>Estado:</strong> {popupData.state}<br />
              <strong>Fecha:</strong> {popupData.time}<br />
              <strong>Placa:</strong> {popupData.plate}<br />
              <strong>Evento:</strong> {popupData.message}<br />
              <strong>Velocidad:</strong> {popupData.speed} km/h<br />
              <strong>Dirección:</strong> {popupData.address}<br />
            </Popup>
          </Marker>
        </MapContainer>
      </div>
      )}
    </div>
  );
};

export default History;
